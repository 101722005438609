import React, { useContext } from 'react';
import * as S from './QuantitySelect.styles';
import FunnelContext from '../../../context/FunnelContext';
import Select from 'react-select';

const SelectContainer = ({ items, useSelectComponent }) => {
  const {
    currentCategory,
    setTubIndex,
    currentTubIndex,
    setCurrentTubIndex,
    setCurrentProduct,
    extraObjects
  } = useContext(FunnelContext);

  const productPrefix =
    currentCategory === `onetime` ? `onetime` : `subscription`;

  const quantityOnChange = evt => {
    setCurrentTubIndex(evt.target.value);
    setTubIndex(evt.target.value);
    setCurrentProduct(`${productPrefix}_${evt.target.value}`);
  };

  const handleSelectChange = ({ value }) => {
    setCurrentTubIndex(value);
    setTubIndex(value);
    setCurrentProduct(`${productPrefix}_${value}`);
  };

  const options = items.map(item => {
    const label =
      extraObjects?.recommendedTubIndex === item.tubIndex &&
      extraObjects?.setRecommendedTub &&
      extraObjects?.recommendedTubText
        ? extraObjects?.recommendedTubText
        : item.label;
    return { value: item.tubIndex, label };
  });

  if (useSelectComponent) {
    return (
      <Select
        classNamePrefix="quantity-select"
        options={options}
        onChange={handleSelectChange}
        inputProps={{ readOnly: true }}
        isSearchable={false}
        defaultValue={options.filter(
          option => option.value === currentTubIndex
        )}
        inputId="quantity-select-input"
      />
    );
  } else {
    return (
      <select
        id="quantity-select-input"
        defaultValue={currentTubIndex}
        aria-label="Quantity"
        onBlur={quantityOnChange}
        onChange={quantityOnChange}
      >
        {items.map(({ tubIndex, label }) => {
          return (
            <option key={tubIndex} value={tubIndex}>
              {label}
            </option>
          );
        })}
      </select>
    );
  }
};

const QuantitySelect = ({ items, label, useSelectComponent, hideItems }) => {
  const { pageDesign } = useContext(FunnelContext);
  return (
    <S.Container pageDesign={pageDesign}>
      <S.Label htmlFor="quantity-select-input">{label}</S.Label>
      {!hideItems && (
        <SelectContainer
          useSelectComponent={useSelectComponent}
          items={items}
        />
      )}
    </S.Container>
  );
};

export default QuantitySelect;
