import styled from 'styled-components';

export const Container = styled.div`
  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                margin-top: 8px;
                & > label {
                    font-size: 18px;
                    margin-bottom: 11px;
                }
                & select {
                    background-color: #EEF0F7;
                    border: 1px solid #E5E8F1;
                    color: #001C72;
                }
                & .quantity-select__control {
                    color: #001C72!important;
                    background-color: #EEF0F7!important;
                   
                }
                & .quantity-select__menu {
                    z-index: 2;
                    color: #001C72!important;
                    background-color: #EEF0F7!important;
                   
                }
                `;
      default:
        return ``;
    }
  }}
`;

export const Label = styled.div`
  margin-top: 5px;
  display: flex;
  font-size: 18px;
  margin-bottom: 11px;
`;
